<template>
    <div class="page">
        <div class="container-login100 p-0">
                <div class="wrap-login100 p-sm-7" v-bind:class="$i18n.locale === 'ru' ? 'p-5' : $i18n.locale === 'en' ? 'p-6' : ''">
                    <div class="text-center pb-4">
                        <a href="/"><img :src="'/assets/images/brand/logo-white.png'" height="60" class="header-brand-img" alt=""></a>
                    </div>
                    <div class="panel panel-primary">

                        <div class="tab-menu-heading">
                            <div class="tabs-menu1">
                                <div>
                                    <p class="mb-1">{{$t('auth.change_password')}} </p>
                                </div>
                            </div>
                        </div>
                        <div class="panel-body tabs-menu-body p-0 pt-4">
                            <div class="tab-content">
                                <div class="fs-12 pb-5">
                                    <div>{{$t('auth.password_requirements')}}:</div>
                                    <div>1. {{$t('auth.at_least_characters')}}.</div>
                                    <div>2. {{$t('auth.at_least_one_uppercase_and_one_lowercase_letter')}}.</div>
                                    <div>3. {{$t('auth.at_least_one_number')}}.</div>
                                </div>
                                <div class="tab-pane active" id="tab5">
                                    <form v-on:submit.prevent="postPassword">
                                        <input type="hidden" name="_token" :value="csrf">
                                        <div class="wrap-input100 validate-input input-group" id="Password-toggle">
                                            <a class="input-group-text bg-white text-muted" role="button">
                                                <i class="zmdi zmdi-eye text-muted" aria-hidden="true"></i>
                                            </a>
                                            <input v-model="user.password" id="password" type="password" name="password" required autocomplete="current-password" class="input100 border-start-0 form-control ms-0" :placeholder="$t('auth.new_password')" />
                                        </div>
                                        <div class="wrap-input100 validate-input input-group" id="Password-toggle1">
                                            <a class="input-group-text bg-white text-muted" role="button">
                                                <i class="zmdi zmdi-eye text-muted" aria-hidden="true"></i>
                                            </a>
                                            <input v-model="user.password_confirmation" id="password_confirmation" type="password" name="password_confirmation" required autocomplete="current-password" class="input100 border-start-0 form-control ms-0" :placeholder="$t('auth.password_confirmation')" />
                                        </div>
                                        <div class="text-warning fs-10">{{error}}</div>
                                        <div class="container-login100-form-btn">
                                            <button class="btn btn-danger w-100 waves-effect waves-light" type="submit">{{$t('auth.save_password')}}</button>
                                        </div>
                                    </form>


                                    <label @click="$i18n.locale === 'ru' ? setLanguage('en') : $i18n.locale === 'en' ? setLanguage('ru') : ''" role="button" class="login-social-icon"><span>{{$i18n.locale === 'ru' ? 'English language' : $i18n.locale === 'en' ? 'Русский язык' : ''}}</span></label>
                                    <div class="text-center">
                                        <p class="text-white-50">©{{new Date().getFullYear()}}  {{$t('home.company_coswick')}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>

import axios from "axios";

export default {
    name: "ChangePasswordComponent",
    props:['csrf'],
    data:()=>({
        user: {
            password: '',
            password_confirmation: '',
        },
        error:''
    }),
    methods:{
        setLanguage(val){
            this.$i18n.locale = val
            localStorage.setItem('lang',this.$i18n.locale)
        },
        postPassword(){
            if(this.user.password === this.user.password_confirmation){
                axios.post('/update-password',this.user).then(response => {
                    window.location.href = '/';
                }).catch(error => {
                    this.error = error.response.data.message
                })
            }
        }
    },
}
</script>

<style scoped>

</style>
